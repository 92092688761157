import { jsx as _jsx } from "react/jsx-runtime";
import ClosePortfolioSidebar from '@widgets/sidebars/ClosePortfolio';
import ConnectExchangeSidebar from '@widgets/sidebars/ConnectExchange';
import { ExchangeSidebar } from '@store/slices/exchange';
// import BalanceManagement from '@widgets/sidebars/BalanceManagement'
import ConnectBinanceExchange from '@widgets/sidebars/ConnectBinanceExchange';
const OverviewSidebar = ({ sidebarType = '', }) => {
    switch (sidebarType) {
        // case ExchangeSidebar.BalanceManagement:
        //     return <BalanceManagement />
        case ExchangeSidebar.ConnectExchange:
            return _jsx(ConnectExchangeSidebar, {});
        case ExchangeSidebar.ClosePortfolio:
            return _jsx(ClosePortfolioSidebar, {});
        case ExchangeSidebar.ConnectBinanceExchange:
            return _jsx(ConnectBinanceExchange, {});
        default:
            return null;
    }
};
export default OverviewSidebar;
